import React from "react";
import Ending from "./Ending";
import Introduction from "./Introduction";
import styled from "styled-components";
import HomePageSection from "./HomePageSection";
import HeroMichelle from "./HeroMichelle";

/**
 * SEO is the only purpose that placing this h1 tag in the page.
 */
const SEOTitle = styled.h1`
  position: absolute;
  font-size: 1px;
  width: 1px;
  height: 1px;
  color: transparent;
  opacity: 0;
  user-select: none;
  z-index: -10;
`;

export default function Index() {
    return (
        <div className={"index-page-parent"}>
            {/*<SEOTitle>認識 BELOTERO®無痕透明質酸彩虹針</SEOTitle>*/}
            <HeroMichelle/>
            {/*<HomePageSection/>*/}
            <Introduction/>
            <Ending/>
        </div>
    );
}
