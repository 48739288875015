module.exports = {
    siteMetadata: {
        siteUrl: "https://www.belotero.com.hk",
    },
    trailingSlash: "always",
    plugins: [
        {
            resolve: "gatsby-plugin-sitemap",
            options: {
                output:"/"
            },
        },
        "gatsby-plugin-robots-txt",
        "gatsby-plugin-sass",
        {
            resolve: "gatsby-plugin-transition-link",
            options: {layout: require.resolve("./src/partials/Layout.js")},
        },
        "gatsby-plugin-scroll-reveal",
        "gatsby-plugin-styled-components",
        "gatsby-plugin-image",
        {
            resolve: "gatsby-plugin-google-analytics",
            options: {
                trackingId: "UA-156247939-1",
                head: true,
            },
        },
        {
            resolve: 'gatsby-source-google-spreadsheets',
            options: {
                spreadsheetId: '10VNAOrCMF_CoZ_9jsg15wD3OjU8_drz0kdJRleb0Jug',
                worksheetTitle: 'HongKongIsland',
                credentials: require('./belotero-google-cloud-credentials.json')
            }
        },
        {
            resolve: "gatsby-plugin-google-tagmanager",
            options: {
                id: "GTM-5JBJVWF",
            },
        },
        "gatsby-plugin-anchor-links",
        "gatsby-plugin-react-helmet",
        {
            resolve: "gatsby-plugin-manifest",
            options: {
                theme_color: "#ffffff",
                icon: "src/images/android-icon-192x192.png",
                icons: [
                    {
                        sizes: "57x57",
                        src: "src/images/apple-icon-57x57.png",
                        type: "image/png",
                    },
                    {
                        sizes: "60x60",
                        src: "src/images/apple-icon-60x60.png",
                        type: "image/png",
                    },
                    {
                        sizes: "72x72",
                        src: "src/images/apple-icon-72x72.png",
                        type: "image/png",
                    },
                    {
                        sizes: "76x76",
                        src: "src/images/apple-icon-76x76.png",
                        type: "image/png",
                    },
                    {
                        sizes: "114x114",
                        src: "src/images/apple-icon-114x114.png",
                        type: "image/png",
                    },
                    {
                        sizes: "120x120",
                        src: "src/images/apple-icon-120x120.png",
                        type: "image/png",
                    },
                    {
                        sizes: "144x144",
                        src: "src/images/apple-icon-144x144.png",
                        type: "image/png",
                    },
                    {
                        sizes: "152x152",
                        src: "src/images/apple-icon-152x152.png",
                        type: "image/png",
                    },
                    {
                        sizes: "180x180",
                        src: "src/images/apple-icon-180x180.png",
                        type: "image/png",
                    },
                    // {rel: "icon", type: "image/png", sizes: "192x192", src: "src/images/android-icon-192x192.png"},
                    // {rel: "icon", type: "image/png", sizes: "32x32", src: "src/images/favicon-32x32.png"},
                    // {rel: "icon", type: "image/png", sizes: "96x96", src: "src/images/favicon-96x96.png"},
                    // {rel: "icon", type: "image/png", sizes: "16x16", src: "src/images/favicon-16x16.png"},
                ],
            },
        },
        "gatsby-plugin-sharp",
        // "gatsby-transformer-sharp",
        {
            resolve: "gatsby-source-filesystem",
            options: {
                name: "images",
                path: "./src/images/",
            },
            __key: "images",
        },
        {
            resolve: "gatsby-plugin-alias-imports",
            options: {
                alias: {
                    "@components": "src/components",
                    "@documents": "src/documents",
                    "@helpers": "src/helpers",
                    "@images": "src/images",
                    "@page-components": "src/page-components",
                    "@partials": "src/partials",
                    "@config": "src/config",
                },
                extensions: ["js"],
            },
        },
        {
            resolve: "gatsby-plugin-google-fonts",
            options: {
                fonts: [
                    "Noto Sans TC\:400",
                    "Noto Sans TC\:700",
                    "Montserrat\:400",
                ],
                display: "swap",
            },
        },
    ],
};
